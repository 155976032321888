import machobanners_sm1 from "./uploads/workGame/machobanners_sm1.png";

const workGameData = [
  {"page-name":"irori",
  "main-img":"irori",
  "src-set1":"irori.png 800w",
  "src-set2":"irori-300x113.png 300w",
  "src-set3":"irori-768x288.png 768w",
  "src-set4":"irori-400x150.png 400w",
  "h-5":"irori",
  "entry-date-posted-on":"October 4, 2019",
  "entry-date-updated":"November 26, 2019",
  "desc":"A cosy, story-driven game about cooking warm food for weary travellers.",

  "page-item-data":{"sm-imgs":["irori_sm1", "irori_sm2", "irori_sm3"],
                    "desc" : "<p><strong>Released on</strong>: <a href=\"https://itsnemo.itch.io/pale-blue-dot\">itch</a></p>\n" +
                        "\n" +
                        "\n" +
                        "                        <p>“As the aroma of savory skewers wafts outside, hungry passengers rest their weary souls at\n" +
                        "                            the hearth. You are the chef on the cross-lands train, in charge of the irori cabin. Cook up\n" +
                        "                            a variety of skewers to warm bellies and hearts as you cross paths with travellers on their\n" +
                        "                            own journeys.”</p>\n" +
                        "\n" +
                        "\n" +
                        "                        <p>Irori is a story-driven game about cooking warm food for weary travellers made by PomePomelo.<br/>\n" +
                        "                        </p>",
                    "credit" : "<p><strong>Credits</strong>: <br/><strong>Design </strong>– Pomepomelo, a collaboration of Daum Park, Jewell Popp, Zhiming Chen<br/><strong>Programming </strong>– Daum Park, Zhiming\n" +
                        "                                Chen<br/><strong>Artist </strong>– Jewell Popp<br/></p>"
                    }
},

  {"page-name":"pale-blue-dot",
    "main-img":"paleBanner",
    "src-set1":"paleBanner.png 800w",
    "src-set2":"paleBanner-300x113.png 300w",
    "src-set3":"paleBanner-768x288.png 768w",
    "src-set4":"paleBanner-400x150.png 400w",
    "h-5":"Pale Blue Dot",
    "entry-date-posted-on":"March 26, 2019",
    "entry-date-updated":"November 27, 2019",
    "desc":"A short interactive fiction about the creation of life and exploration of human desires.",

    "page-item-data":{"sm-imgs":["paleBanner_sm1", "paleBanner_sm2", "paleBanner_sm3"],
        "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://itsnemo.itch.io/pale-blue-dot\">itch</a></p>\n" +
            "\n" +
            "\n" +
            "\n" +
            "<p>“Consider again that dot. That’s here, that’s home, that’s us. On it everyone you love, everyone you know, everyone you ever heard of, every human being who ever was, lived out their lives. <br/><br/>It’s time for you to explore that dot.”<br/></p>\n" +
            "\n" +
            "\n" +
            "\n" +
            "<p>Pale Blue Dot<strong>&nbsp;</strong>is a short interactive fiction about the creation of life and exploration of human desires. </p>",
        "credit":""
    }
  },

  {"page-name":"beam-team",
    "main-img":"beamBanner",
    "src-set1":"beamBanner.png 800w",
    "src-set2":"beamBanner-300x113.png 300w",
    "src-set3":"beamBanner-768x288.png 768w",
    "src-set4":"beamBanner-400x150.png 400w",
    "h-5":"Beam Team",
    "entry-date-posted-on":"March 26, 2019",
    "entry-date-updated":"November 27, 2019",
    "desc":"A frantic, 2-player, couch co-op game revolving around planets, teamwork, and destruction.",

      "page-item-data":{"sm-imgs":[
          "sqsSa_sm1",
          "sqsSa_sm2",
          "sqsSa_sm3",
          "sqsSa_sm4",
          "sqsSa_sm5",
          "sqsSa_sm6",
          "sqsSa_sm7"
          ],
            "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://no-moss-studios.itch.io/beam-team\">Itch</a>, Coming to Arcade</p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>Beam Team is a frantic, 2-player, couch co-op game revolving around planets, teamwork, and destruction. </p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>I was the lead designer and programmer for this project. Development first began in 2016 and later further developed with No Moss Studios with a larger team.</p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>With this game, I wanted to foster friendship through memorable moments.</p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p><strong>Exhibitions and Showcases:</strong><br/>– PAX Aus 2019<br/>– PAX Aus 2018<br/>– Smash 2018 <br/>– Gamma Con 2018<br/>– Featured in PC Powerplay’s December 2018 edition. </p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>The game received multiple positive feedback.</p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<blockquote class=\"wp-block-quote\"><p><em>Was playing the game today at Smash Sydney 2018 and all I can say was it was the best game I played throughout the whole event</em>. </p><p></p><cite> <strong>jaycoe5</strong> <br/></cite></blockquote>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p></p>",
            "credit":"<p><strong>Credits</strong>:<br/><strong>Design, Production </strong>– Daum Park<br/><strong>Programming&nbsp;</strong>– Daum Park, Reuben Moorhouse<br/><strong>Artist&nbsp;</strong>– Sydney Liao, Jay Tan, Nadia Lee<br/><strong>Music</strong> – Christine Pan, Nathan Ratsavanah</p>"
      }
  },
  {"page-name":"clouded",
    "main-img":"cloudedbanners",
    "src-set1":"cloudedbanners.png 800w",
    "src-set2":"cloudedbanners-300x113.png 300w",
    "src-set3":"cloudedbanners-768x288.png 768w",
    "src-set4":"cloudedbanners-400x150.png 400w",
    "h-5":"Clouded",
    "entry-date-posted-on":"March 26, 2019",
    "entry-date-updated":"November 27, 2019",
    "desc":"5 minutes of memory.",

      "page-item-data":{"sm-imgs":[
              "cloudedbanners_sm1",
              "cloudedbanners_sm2",
              "cloudedbanners_sm3"
          ],
      "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://no-moss-studios.itch.io/clouded\">Itch</a> and <a href=\"https://store.steampowered.com/app/886350/Clouded/\">Steam</a></p>\n" +
          "\n" +
          "\n" +
          "\n" +
          "<p>You have 5 minutes to get into the basement. Find clues in the house, and write down anything you find on your arm. After 5 minutes, you’ll fall unconscious, and wake up again outside the house. Only, the notes you find on your arm aren’t what you remember writing.</p>\n" +
          "\n" +
          "\n" +
          "\n" +
          "<p>Created for NSI game jam.</p>",
      "credit":"<p><strong>Credits:</strong><br/><strong>Development:</strong> Daum Park, Reuben Moorhouse<br/></p>"
      }
  },
  {"page-name":"sound-of-my-town",
    "main-img":"soundBanner",
    "src-set1":"soundBanner.png 800w",
    "src-set2":"soundBanner.-300x113.png 300w",
    "src-set3":"soundBanner.-768x288.png 768w",
    "src-set4":"soundBanner.-400x150.png 400w",
    "h-5":"Sound of my Town",
    "entry-date-posted-on":"March 26, 2019",
    "entry-date-updated":"November 27, 2019",
    "desc":"SimCity meets music sequencer game.",

      "page-item-data":{"sm-imgs":[
              "OgK_vT-1024x267_sm1",
              "OgK_vT-1024x267_sm2",
              "OgK_vT-1024x267_sm3"
          ],
          "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://no-moss-studios.itch.io/sound-of-my-town\">Itch</a></p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p>Sound of my Town is a cute SimCity meets music sequencer game. As the mayor, build structures and plant forests to create a town with its own unique tune. </p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p>Players can build structures and plants, each having their own unique sounds and chimes. As the King passes through the town it’ll play each of the sounds, together making a music piece. </p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p>With this game, I wanted to explore the player’s musical creativity and teach music through play. </p>",
          "credit":""
    }
  },
  {"page-name":"my-magpie",
    "main-img":"magpieBanner",
    "src-set1":"magpieBanner.png 800w",
    "src-set2":"magpieBanner.-300x113.png 300w",
    "src-set3":"magpieBanner.-768x288.png 768w",
    "src-set4":"magpieBanner.-400x150.png 400w",
    "h-5":"My Magpie",
    "entry-date-posted-on":"March 26, 2019",
    "entry-date-updated":"November 27, 2019",
    "desc":"A short interactive story about nurturing an injured Magpie and exploring a relationship between mother and daughter.",

      "page-item-data":{"sm-imgs":[
              "magpieBanner_sm1",
              "magpieBanner_sm2",
              "magpieBanner_sm3"
          ],
          "desc":"<p><strong>Released on:</strong> <a href=\"https://itsnemo.itch.io/my-magpie\">Itch</a></p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p>“You meet an injured magpie, and smuggle it home to nurse it back to health. You don’t know much about wildlife care, but there’s nothing some healthy food and lots of love can’t fix, right?<br/><br/>After all, your new friend seems to feel right at home in your room.”</p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p>My magpie, a short interactive story about nurturing an injured Magpie and exploring a relationship between mother and daughter. <br/></p>",
          "credit":"<p><strong>Credits:</strong><br/><strong>Artist:</strong> Sydney Liao<br/><strong>Design, Programmer:</strong> Daum Park<br/></p>"
      }
  },
  {"page-name":"pitch-please",
    "main-img":"ZGO__i3",
    "src-set1":"ZGO__i3.png 776w",
    "src-set2":"ZGO__i3-300x90.png 300w",
    "src-set3":"ZGO__i3-768x231.png 768w",
    "src-set4":"ZGO__i3-400x120.png 400w",
    "h-5":"Pitch, Please",
    "entry-date-posted-on":"March 26, 2019",
    "entry-date-updated":"November 27, 2019",
    "desc":"A pitch based rhythmic singalong about a baby bluebird finding its place in the world through singing.",

      "page-item-data":{"sm-imgs":[
              "ZGO__i3_sm1",
              "ZGO__i3_sm2",
              "ZGO__i3_sm3"
          ],
          "desc":"<p><strong>Released on: </strong><a href=\"https://no-moss-studios.itch.io/pitch-please\"><strong>Itch</strong></a></p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p><em>Why isn’t there bird school for singing?</em></p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p><em>As a fledgling bird you just want to fit in with the prim and proper birds. Now every bird knows that means you need to sing along. But no one taught you how to sing.</em></p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p><em>Sing along, catch on fire and become the star you were born to be!</em></p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p>Pitch, Please is a pitch based rhythmic singalong about a baby bluebird finding its place in the world through singing.</p>",
          "credit":"<p><strong>Credits:<br/>Design:</strong> Daum Park, Zhiming Chen<br/><strong>Programmer: </strong>Zhiming Chen<br/><strong>Artist:</strong> Sydney Liao<br/></p>"
      }
  },
  {"page-name":"stumped",
    "main-img":"stumpedbanner",
    "src-set1":"stumpedbanner.png 800w",
    "src-set2":"stumpedbanner-300x113.png 300w",
    "src-set3":"stumpedbanner-768x288.png 768w",
    "src-set4":"stumpedbanner-400x150.png 400w",
    "h-5":"Stumped",
    "entry-date-posted-on":"March 26, 2019",
    "entry-date-updated":"November 27, 2019",
    "desc":"A calming, interactive short story about a boy and a tree who steals his frisbee.",

      "page-item-data":{"sm-imgs":[
              "RWFKO-1024x465_sm1",
              "RWFKO-1024x465_sm2",
              "RWFKO-1024x465_sm3"
          ],
          "desc":"<p><strong>Released on: </strong><a href=\"https://no-moss-studios.itch.io/pitch-please\"><strong>Itch</strong></a></p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p><em>Why isn’t there bird school for singing?</em></p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p><em>As a fledgling bird you just want to fit in with the prim and proper birds. Now every bird knows that means you need to sing along. But no one taught you how to sing.</em></p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p><em>Sing along, catch on fire and become the star you were born to be!</em></p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p>Pitch, Please is a pitch based rhythmic singalong about a baby bluebird finding its place in the world through singing.</p>",
          "credit":"<p><strong>Credits:<br/>Design:</strong> Daum Park, Zhiming Chen<br/><strong>Programmer: </strong>Zhiming Chen<br/><strong>Artist:</strong> Sydney Liao<br/></p>"
      }
  },
  {"page-name":"secret-handshake",
    "main-img":"secretBanner",
    "src-set2":"secretBanner-300x113.png 300w",
    "src-set3":"secretBanner-768x288.png 768w",
    "src-set4":"secretBanner-400x150.png 400w",
    "h-5":"Secret HandShake",
    "entry-date-posted-on":"March 26, 2019",
    "entry-date-updated":"November 27, 2019",
    "desc":"A rhythm based handshaking game.",

      "page-item-data":{"sm-imgs":[
              "secretBanner_sm1",
              "secretBanner_sm2",
              "secretBanner_sm3"
          ],
          "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://no-moss-studios.itch.io/secret-handshake\">Itch</a></p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p>Secret Handshake is a rhythm based handshaking game. </p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p><em>You’re the new kid in town.</em></p>\n" +
              "\n" +
              "\n" +
              "\n" +
              "<p><em>The only way to climb the food chain is to make friends in the right places – except around these parts, people only talk if you’re cool enough to know their secret handshakes.</em></p>",
          "credit":"<p><strong>Credits</strong>:<br/><strong>Design, Programming</strong>– Daum Park<br/><strong>Artist&nbsp;</strong>– Sydney Liao<br/></p>"
      }
  },


    {"page-name":"jack-the-super-prawn",
        "main-img":"jackbanner",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"Jack The Super Prawn",
        "entry-date-posted-on":"March 11, 2018",
        "entry-date-updated":"November 27, 2019",
        "desc":"A game to help educate children about aquatic ecology and environmental issues around plastic pollution.",

        "page-item-data":{"sm-imgs":[
                "jackbanner_sm1",
                "jackbanner_sm2",
                "jackbanner_sm3"
            ],
            "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://itunes.apple.com/us/app/jack-the-super-prawn/id1384343036?ls=1&amp;mt=8\">IOS </a>and <a href=\"https://play.google.com/store/apps/details?id=com.NoMossStudios.JackOceanHero\">Android</a></p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>Jack the Super Prawn is a client end-end project. It was created to help educate children about aquatic ecology and environmental issues around plastic pollution. </p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>It has now been purchased by several Australian schools and the future of Jack the Super Prawn is bright. </p>",
            "credit":"<p><strong>Credits</strong>:<br/><strong>Production</strong> – Daum Park  <br/><strong>Design</strong> – Daum Park, Zhiming Chen<br/><strong>Programming</strong> – Daum Park, Zhiming Chen<br/><strong>Artist</strong> – Sydney Liao<br/> </p>"
        }
    },
    {"page-name":"a-l-i-c-e",
        "main-img":"alicebanner",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"A.L.I.C.E",
        "entry-date-posted-on":"February 24, 2018",
        "entry-date-updated":"November 27, 2019",
        "desc":"A game to help educate students about cyber security.",

        "page-item-data":{"sm-imgs":[
                "alicebanner_sm1",
                "alicebanner_sm2",
                "alicebanner_sm3"
            ],
            "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://itunes.apple.com/au/app/a-l-i-c-e/id1308819837?mt=8\">IOS </a>and <a href=\"https://play.google.com/store/apps/details?id=com.S1T2.UNSWCyber\">Android</a></p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p> A.L.I.C.E is a retro style 2D platform side-scrolling game, with enemies to zap, puzzles to solve, and an overall learning experience. </p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>To encourage UNSW students to be more aware of their actions online, UNSW has built this app to further increase awareness of common Cyber Security threats and counter-measures that can be taken.  </p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>The game received multiple positive feedback.</p>",
            "credit":"<p><strong>Credits:</strong><br/> UNSW, S1T2, No Moss Studios</p>"
        }
    },
    {"page-name":"cosmos",
        "main-img":"cosmosbanner",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"Cosmos",
        "entry-date-posted-on":"April 11, 2017",
        "entry-date-updated":"November 27, 2019",
        "desc":"Exploring the feeling of long distance relationship.",

        "page-item-data":{"sm-imgs":[
                "cosmosbanner_sm1",
                "cosmosbanner_sm2",
                "cosmosbanner_sm3"
            ],
            "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://no-moss-studios.itch.io/cosmos\">itch</a></p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>Cosmos is an experimental game to explore the feeling of long distance relationship. </p>",
            "credit":""
        }
    },

    {"page-name":"sprout-up-2",
        "main-img":"gsNl3i",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"Sprout Up",
        "entry-date-posted-on":"April 11, 2017",
        "entry-date-updated":"November 27, 2019",
        "desc":"A cute mobile game about a journey of a humble Sprout towards blossom. ",

        "page-item-data":{"sm-imgs":[
                "gsNl3i_sm1",
                "gsNl3i_sm2",
                "gsNl3i_sm3"
            ],
            "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://itunes.apple.com/us/app/sprout-up/id1199785376?mt=8\">IOS</a> and <a href=\"https://play.google.com/store/apps/details?id=com.itsDaumGames.Sprout\">Android</a></p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>Sprout Up is a cute mobile game about a journey of a humble Sprout towards blossom. </p>",
            "credit":""
        }
    },
    {"page-name":"i-am-bomb",
        "main-img":"bombbanner",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"I Am Bomb",
        "entry-date-posted-on":"September 11, 2016",
        "entry-date-updated":"November 27, 2019",
        "desc":"A story of a bomb who suddenly gains sentience and attempts to form a relationship with the human defusing it.  ",

        "page-item-data":{"sm-imgs":[
                "bombbanner_sm1",
                "bombbanner_sm2",
                "bombbanner_sm3",
                "bombbanner_sm4",
            ],
            "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://itunes.apple.com/us/app/i-am-bomb/id1314860845#?platform=iphone\">IOS</a> and <a href=\"https://play.google.com/store/apps/details?id=com.NoMossStudios.IAmBomb\">Android</a></p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>It tells the story of a bomb who suddenly gains sentience and attempts to form a relationship with the human defusing it. </p>",
            "credit":""
        }
    },

    {"page-name":"ritual-ware-2",
        "main-img":"ritualbanners",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"Ritual Ware",
        "entry-date-posted-on":"January 25, 2016",
        "entry-date-updated":"November 27, 2019",
        "desc":"Follow the daily ritual of a regular human being who in no way is a demon.",

        "page-item-data":{"sm-imgs":[
                "ritualbanners_sm1",
                "ritualbanners_sm2",
                "ritualbanners_sm3"
            ],
            "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://globalgamejam.org/2016/games/ritual-ware\">Global Game Jam 2016</a></p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>Ritual Ware was a game made for Global Game Jam 2016. Follow the daily ritual of a regular human being who in no way is a demon. Partake in the standard working man’s daily tasks including waking up, picking nose hair, sacrificing a sheep and many more fast-paced mini-games.  </p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>The game was awarded “Real Dark Real Quick” by Sydney Game Developers Community.</p>",
            "credit":""
        }
    },
    {"page-name":"cry-of-the-dolphin",
        "main-img":"crybanners",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"Cry of the Dolphin",
        "entry-date-posted-on":"November 2, 2015",
        "entry-date-updated":"November 27, 2019",
        "desc":"Visualize your surroundings through ‘Echolocation’. Let your echo guide your way towards survival and away from predators.",

        "page-item-data":{"sm-imgs":[
                "crybanners_sm1",
                "crybanners_sm2",
                "crybanners_sm3"
            ],
            "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://gamejolt.com/games/cry-of-the-dolphin/125454\">GameJolt</a></p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>Cry of the Dolphin is a top-down survival game. Survive The Dark, Blue Sea as a Dolphin losing vision. Visualize your surroundings through ‘Echolocation’. Let your echo guide your way towards survival and away from predators. </p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>The game was awarded 1st place for Innovation and Gameplay by Microsoft and UTS Game Design Studio. </p>",
            "credit":""
        }
    },
    {"page-name":"the-big-red-button-hectic-games-jam",
        "main-img":"brbbanners",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"The BIG Red Button",
        "entry-date-posted-on":"June 25, 2015",
        "entry-date-updated":"November 27, 2019",
        "desc":"An interactive puzzle game about defusing Bob an artificial intelligent bomb that seeks to question its existence and purpose.",

        "page-item-data":{"sm-imgs":[
                "brbbanners_sm1",
                "brbbanners_sm2",
                "brbbanners_sm3"
            ],
            "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://gamejolt.com/games/the-big-red-button/77531\">GameJolt</a></p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>The BIG Red Button is a game made for <a href=\"http://hecticgames.com/\">Hectic Game Jam</a> #6 run by North Sydney Institute. It is an interactive puzzle game about defusing Bob an artificial intelligent bomb that seeks to question its existence and purpose.</p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>The game was 6th place out of 28 entries.</p>",
            "credit":""
        }
    },
    {"page-name":"vengador",
        "main-img":"OverallGuideline3",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"Vengador",
        "entry-date-posted-on":"June 23, 2015",
        "entry-date-updated":"November 27, 2019",
        "desc":"Vengador was a side-scroll beat-em-up game to promote the comic book series Vengador by James Gilarte.",

        "page-item-data":{"sm-imgs":[
                "OverallGuideline3_sm1",
                "OverallGuideline3_sm2"
            ],
            "desc":"<p>Vengador was a side-scroll beat-em-up game to promote the comic book series Vengador by <a href=\"https://www.facebook.com/jamesgilarte?fref=ts\">James Gilarte</a>. </p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>The game was showcased at Sydney Supanova on 20th – 21st June 2015. It received positive feedback from Vengador Fans and the public.</p>",
            "credit":""
        }
    },

    {"page-name":"lupa-hectic-games-jam",
        "main-img":"lupabanner",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"LUPA",
        "entry-date-posted-on":"June 23, 2015",
        "entry-date-updated":"November 27, 2019",
        "desc":"A mother wolf trying to nurture two human babies Romulus and Remus by gathering food from the wild.",

        "page-item-data":{"sm-imgs":[
                "lupabanner_sm1",
                "lupabanner_sm2",
                "lupabanner_sm3"
            ],
            "desc":"<p>Made for <a href=\"http://hecticgames.com/\">Hectic Game Jam</a> #5 run by North Sydney Institute. Play as Lupa, a mother wolf trying to nurture two human babies Romulus and Remus by gathering food from the wild. </p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>The game was awarded 1st place out of 27 entries for gameplay.<a href=\"https://play.google.com/store/apps/details?id=com.itsDaumGames.Sprout\"></a></p>",
            "credit":""
        }
    },
    {"page-name":"magic-benny-vs-the-woodcutters-hectic-games-jam",
        "main-img":"magicbanner",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"Magic Benny vs The WoodCutters",
        "entry-date-posted-on":"January 7, 2015",
        "entry-date-updated":"November 26, 2019",
        "desc":"A wizard trying to prove himself to the great wizards by whacking animals to stop the woodcutters from chopping down the tree.",

        "page-item-data":{"sm-imgs":[
                "magicbanner_sm1",
                "magicbanner_sm2"

            ],
            "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://gamejolt.com/games/magic-benny-vs-the-woodcutters/42120\">GameJolt</a></p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>Play as Magic Benny, a wizard trying to prove himself to the great wizards by whacking animals to stop the woodcutters from chopping down the tree.</p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>Made for <a href=\"http://hecticgames.com/\">Hectic Game Jam</a> #4. The theme of the jam was requested by children in hospitals and orphanages. This particular game was dedicated to John Taylor, Sloan Cross, and Thomas Whites.</p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>The game was awarded 1st place overall and awarded Highest in Polish and Replayability.<br/></p>",
            "credit":"",
            "tags":"<div id=\"tags\"><span class=\"icon-tags\"></span> <p><a href=\"https://itsdaum.com/tag/android/\" rel=\"tag\">android</a>, <a href=\"https://itsdaum.com/tag/hectic-games-jam/\" rel=\"tag\">hectic games jam</a></p></div>"
        }
    },
    {"page-name":"link-ludum-dare-30",
        "main-img":"linkbanner",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"Link",
        "entry-date-posted-on":"August 26, 2014",
        "entry-date-updated":"November 27, 2019",
        "desc":"A game about re-establishing a connection between people.",

        "page-item-data":{"sm-imgs":[
                "linkbanner_sm1",
                "linkbanner_sm2",
                "linkbanner_sm3"
            ],
            "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"https://www.kongregate.com/games/hundredhundred/link\">Kongregate</a></p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>Link is a game about re-establishing a connection between people. </p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>Made for Ludum Dare 30. The game came 9th place for Fun out of 1045 entries.</p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p><strong>Ratings:</strong></p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<table class=\"wp-block-table\"><tbody><tr><td></td><td>Coolness</td><td>69%</td></tr><tr><td>#9</td><td>Fun(Jam)</td><td>4.18</td></tr><tr><td>#33</td><td>Innovation(Jam)</td><td>4.03</td></tr><tr><td>#45</td><td>Overall(Jam)</td><td>3.95</td></tr><tr><td>#72</td><td>Humor(Jam)</td><td>3.52</td></tr><tr><td>#75</td><td>Audio(Jam)</td><td>3.81</td></tr><tr><td>#124</td><td>Mood(Jam)</td><td>3.65</td></tr><tr><td>#270</td><td>Graphics(Jam)</td><td>3.63</td></tr><tr><td>#413</td><td>Theme(Jam)</td><td>3.11</td></tr></tbody></table>",
            "credit":"",
            "tags":"<div id=\"tags\"><span class=\"icon-tags\"></span> <p><a href=\"https://itsdaum.com/tag/ld-30/\" rel=\"tag\">#LD 30</a>, <a href=\"https://itsdaum.com/tag/ld48/\" rel=\"tag\">#LD48</a>, <a href=\"https://itsdaum.com/tag/artwork/\" rel=\"tag\">artwork</a>, <a href=\"https://itsdaum.com/tag/game-jam/\" rel=\"tag\">game jam</a>, <a href=\"https://itsdaum.com/tag/ludum-dare/\" rel=\"tag\">Ludum dare</a>, <a href=\"https://itsdaum.com/tag/programming/\" rel=\"tag\">programming</a></p></div>"
        }
    },
    {"page-name":"macho-knight",
        "main-img":"machobanners",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"Macho Knight",
        "entry-date-posted-on":"January 7, 201",
        "entry-date-updated":"November 26, 2019",
        "desc":"Macho Knight only has one goal in mind, to protect his Lover from the Hooligans",

        "page-item-data":{"sm-imgs":[
                "machobanner_sm1",
                "machobanner_sm2",
                "machobanner_sm3",
                "machobanners_sm4",
                "machobanners_sm5"
            ],
            "desc":"<p><span style=\"background-color: rgb(232, 234, 235);\"><b>Released on:</b></span> <a href=\"http://ludumdare.com/compo/ludum-dare-31/?action=preview&amp;uid=42294\">Ludum Dare 31</a></p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>Macho Knight was a game made for Ludum Dare 31.</p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p>Play as the mighty Macho Knight. Macho Knight only has one goal in mind, to protect his Lover from the Hooligans that are trying to kidnap her. You must use your mighty Macho Shield (machoman belt) to fling the hooligans off the fort! </p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<p><strong>Ratings:</strong></p>\n" +
                "\n" +
                "\n" +
                "\n" +
                "<table class=\"wp-block-table\"><tbody><tr><td></td><td>Coolness</td><td>100%</td></tr><tr><td>#130</td><td>Humor(Jam)</td><td>3.48</td></tr><tr><td>#146</td><td>Audio(Jam)</td><td>3.56</td></tr><tr><td>#170</td><td>Fun(Jam)</td><td>3.53</td></tr><tr><td>#199</td><td>Graphics(Jam)</td><td>3.88</td></tr><tr><td>#233</td><td>Overall(Jam)</td><td>3.55</td></tr><tr><td>#310</td><td>Theme(Jam)</td><td>3.75</td></tr><tr><td>#342</td><td>Mood(Jam)</td><td>3.27</td></tr><tr><td>#403</td><td>Innovation(Jam)</td><td>3.19</td></tr></tbody></table>",
            "credit":"",
            "tags":"<div id=\"tags\"><span class=\"icon-tags\"></span> <p><a href=\"https://itsdaum.com/tag/entire-game-on-one-screen/\" rel=\"tag\">entire game on one screen</a>, <a href=\"https://itsdaum.com/tag/ld31/\" rel=\"tag\">ld31</a>, <a href=\"https://itsdaum.com/tag/ludum-dare/\" rel=\"tag\">Ludum dare</a></p></div>"
        }
    }




  ];

export default workGameData;
