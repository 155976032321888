import React from 'react';
import workGameData from '../../data/workGameData';
import workImgs from '../../data/uploads/workGame';
import WorkThumbDetail from './WorkThumbDetail';

function Game(props){

    return(

        <WorkThumbDetail workMode="game" workData={workGameData} workImgs={workImgs}/>

    );
}

export default Game;