import React from 'react';
import mainImg from '../../data/uploads/about/P1010688-e1467906501291-1024x979.jpg';

function About() {
    return (
        <div id="container" className="row">
            <div id="primary" className="large-7 medium-9 small-11 small-centered columns">
                <article className="articlebox post-577 page type-page status-publish hentry">
                    <header className="entry-header entry-header-single">
                        <h1 className="entry-title">
                            Me in 60 Seconds </h1>
                    </header>
                    <div className="entry-content">

                        <p><strong>Daum Park</strong> is a <strong>Game Designer, Programmer, and
                            Creator.&nbsp;</strong></p>


                        <p>Working towards <strong>Capturing &amp; Celebrating Human
                            Imagination</strong> with <strong>Games.</strong></p>


                        <p>Seeking to <strong>craft</strong> games that:<br/>– Challenges
                            the <strong>Perception </strong>of
                            games.<br/>– Explores or Inspires
                            one’s <strong>Creativity.</strong><br/>– <strong>Connects</strong> people.<br/>– is <strong>Fun,
                                Accessible</strong>, and <strong>Meraki</strong>.</p>


                        <p>Delivered some good, some strange and some really<strong> unusual games.</strong> Calls
                            them <a
                                rel="noreferrer noopener" href="https://itsnemo.itch.io/" target="_blank"><strong>Playful
                                Interactives!</strong></a></p>


                        <p>Co-founded <strong>No Moss Studios,</strong> a game studio. Grew and lead a close-knit team
                            of game
                            developers and worked towards<em> Elevating the Culture of Play.</em></p>


                        <p>Eager to <strong>learn </strong>and <strong>create</strong>. Particularly
                            around <strong>creativity</strong>, <strong>games </strong>and <strong>optimizing
                                life</strong>.
                        </p>


                        <h2>A Picture of me</h2>


                        <div className="wp-block-image">
                            <figure className="aligncenter"><img
                                src={mainImg} alt=""
                                className="wp-image-527"

                                sizes="(max-width: 1024px) 100vw, 1024px" />
                                <figcaption>Me</figcaption></figure>
                        </div>


                        <p>Last Updated: 20-DECEMBER-19</p>
                    </div>

                </article>
            </div>


        </div>

);
}
export default About;

