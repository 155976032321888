import DDbanners2 from './DDbanners2.png';
import gal636352 from './gal636352.png';
import blake21024676 from "../workArt/blake21024676.png";
import ClubNight1024738 from "../workArt/ClubNight1024738.png";
import EmilyRudd1024751 from "../workArt/EmilyRudd1024751.png";
import Kyla27491024 from "../workArt/Kyla27491024.png";
import sketch7151024566 from "../workArt/sketch7151024566.png";
import SkullGirl1024881 from "../workArt/SkullGirl1024881.png";

const workArtImgs = {

    'DDbanners2-imgs' : {
        DDbanners2
    },
    'gal636352-imgs' : {
        gal636352,
        blake21024676,
        ClubNight1024738,
        EmilyRudd1024751,
        Kyla27491024,
        sketch7151024566,
        SkullGirl1024881

    }

};


export default workArtImgs;