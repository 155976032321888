import React from 'react';

function Footer() {

   return(
       <footer id="site-footer">

        <div id="copyright" className="row">
            <div className="columns">
                ©&nbsp;2020&nbsp; <a href="https://itsdaum.com/" title="Daum Park - Capturing Human Imagination">
                Daum Park </a>
            </div>
        </div>



        <div className="rb row text-center">WordPress Theme by <a href="https://richwp.com/">RichWP</a></div>
    </footer>
   );
}

export default Footer;