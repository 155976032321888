import React from "react";
//---- use Link from react-router-dom -----------
import {
    Link, useParams, useRouteMatch, useLocation
} from "react-router-dom";


function Header() {
    //const params = useParams();
    //let match = useRouteMatch("");
    const liClass=" menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-26";
    let location = useLocation();
    let homeClass = location.pathname === "/" ? "current-menu-item current_page_item" : "";
    homeClass += liClass;
    let aboutClass = location.pathname.match(/^\/about/)  ? "current-menu-item current_page_item" : "";
    aboutClass += liClass;
    let nowClass = location.pathname.match(/^\/now/) ? "current-menu-item current_page_item" : "";
    nowClass += liClass;
    const contactsClass = location.pathname.match(/^\/contacts/) ? "current-menu-item current_page_item" + liClass : liClass;

    const workGameClass = location.pathname.match(/^\/work-game/) ? "current-menu-item current_page_item" + liClass : liClass;
    const workArtClass = location.pathname.match(/^\/work-art/) ? "current-menu-item current_page_item" + liClass : liClass;


    return (
        <>

            <header id="top-menu" className="top-bar" itemScope="itemscope">

                <div className="menu-container-mobile" data-responsive-toggle="menu-container" data-hide-for="large"
                     style={{'display': 'none'}}>
                    <button className="icon-menu" type="button" data-toggle=""></button>
                </div>

                <div className="topbar-title title-logo" itemScope="itemscope" itemType="http://schema.org/WPHeader"
                     role="banner">

                    <Link to="/" title="Daum Park - Capturing Human Imagination">
                        <h1 id="sitetitle">Daum Park</h1>
                    </Link>

                </div>

                <div id="menu-container" className="menu-container">
                    <nav className="richprimarymenu" itemType="http://schema.org/SiteNavigationElement"
                         role="navigation">
                        <ul id="menu-menu-1" className="vertical large-horizontal menu dropdown"
                            data-responsive-menu="accordion large-dropdown" role="menubar"
                            data-dropdown-menu="928ldq-dropdown-menu"
                            data-mutate="75esq4-responsive-menu">
                            {/*<li id="menu-item-26"*/}
                            {/*className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-26"*/}
                            {/*role="menuitem"><a href="http://itsdaum.com/" aria-current="page">Home</a></li>*/}


                            <li className={homeClass} id="menu-item-26"

                                role="menuitem">
                                <Link  to="/">Home</Link>
                            </li>


                            <li className={nowClass} id="menu-item-1101"

                                role="menuitem">
                                <Link to="/now">Now</Link>
                            </li>
                            <li id="menu-item-585"
                                className={aboutClass}
                                role="menuitem">
                                <Link to="/about">About</Link>
                            </li>
                            <li id="menu-item-119"
                                className={workGameClass}
                                role="menuitem" aria-haspopup="true" aria-label="Work">
                                <Link to="/work-game">Work-Game</Link>
                                {/*<ul className="vertical menu submenu is-dropdown-submenu first-sub" data-submenu=""*/}
                                    {/*role="menu">*/}
                                    {/*<li id="menu-item-610"*/}
                                        {/*className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-610 is-submenu-item is-dropdown-submenu-item"*/}
                                        {/*role="menuitem">*/}
                                        {/*<Link to="/work/game">Game</Link>*/}
                                    {/*</li>*/}
                                    {/*<li id="menu-item-611"*/}
                                        {/*className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-611 is-submenu-item is-dropdown-submenu-item"*/}
                                        {/*role="menuitem">*/}
                                        {/*<Link to="/work/art">Art</Link>*/}
                                    {/*</li>*/}
                                {/*</ul>*/}
                            </li>
                            <li id="menu-item-119"
                                className={workArtClass}
                                role="menuitem" aria-haspopup="true" aria-label="Work">
                                <Link to="/work-art">Work-Art</Link>
                                {/*<ul className="vertical menu submenu is-dropdown-submenu first-sub" data-submenu=""*/}
                                {/*role="menu">*/}
                                {/*<li id="menu-item-610"*/}
                                {/*className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-610 is-submenu-item is-dropdown-submenu-item"*/}
                                {/*role="menuitem">*/}
                                {/*<Link to="/work/game">Game</Link>*/}
                                {/*</li>*/}
                                {/*<li id="menu-item-611"*/}
                                {/*className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-611 is-submenu-item is-dropdown-submenu-item"*/}
                                {/*role="menuitem">*/}
                                {/*<Link to="/work/art">Art</Link>*/}
                                {/*</li>*/}
                                {/*</ul>*/}
                            </li>
                            <li id="menu-item-595"
                                className={contactsClass}
                                role="menuitem">
                                <Link to="/contacts">Contacts</Link>
                            </li>
                        </ul>
                    </nav>
                    <ul id="iconmenu" className="menu richiconmenu">

                        <li id="searchicon" className="icon-search menu-item">
                            <a href="#"> Search </a>

                        </li>
                    </ul>
                </div>
            </header>


            {/* search component */}

            <div id="searchwrap" style={{"zIndex":100}}>
                <div className="row" >
                    <div className="columns">
                        {/*<form role="search" method="get" id="searchform" action="http://itsdaum.com/">*/}
                        <form role="search" method="get" id="searchform" action="/">
                            <div className="input-group">
                                <input
                                    type="text"
                                    onChange={()=>{}}
                                    className="input-group-field"
                                    value="" name="s" id="s"
                                    placeholder="Search"/>
                                <div className="input-group-button">
                                    <input type="submit" id="searchsubmit" value="Search" className="button"/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    );

}

export default Header;