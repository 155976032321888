import React from 'react';
import workData from '../../data/workArtData';
import workImgs from '../../data/uploads/workArt';
import WorkThumbDetail from './WorkThumbDetail';

function Art(props){

    return(

        <WorkThumbDetail workMode="art" workData={workData} workImgs={workImgs}/>

    );
}

export default Art;