import React from 'react';
import {Link, useParams, useQuery} from 'react-router-dom';


function WorkThumbDetail(props){
    const {workMode, workData, workImgs} = props;
    //const params = useParams();
    //console.log(params);

    return (
        <div id="container" className="row" style={{"margin-top":"100px"}}>
            <div id="primary" className="columns">
                <div className="medium-up-2 row">

                    { workData.map(item =>{
                        return (<article id="post-949"
                                         className="column postbox post-949 post type-post status-publish format-standard has-post-thumbnail hentry category-games category-work">
                            <div className="postboxinner">
                                <Link to={"/work-" + workMode + "/" + item["main-img"]} >
                                <div>
                                    <div className="postlistthumb">
                                        <div className="postlistthumbwrap" style={{"backgroundColor":"#fff", "border":"outset #dedede 4px"}}>
                                            <img width="800" height="300" src={workImgs[item["main-img"] + "-imgs"][item["main-img"]]}
                                                 className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                 alt=""

                                                 sizes="(max-width: 800px) 100vw, 800px"/>
                                            <div className="viewpostdisplay">
                                                View Post →
                                            </div>
                                        </div>
                                    </div>

                                    <header className="entry-header">
                                        <h5 className="entry-title">
                                            {item["h-5"]} </h5>
                                    </header>
                                    <div className="postbox-entry-meta">Daum Park · <span className="screen-reader-text">Posted on</span>
                                        <span className="entry-date published" dateTime="2019-10-04T07:48:01+00:00">{item["entry-date-posted-on"]}
                                        </span>
                                        <time className="updated" dateTime="2019-11-26T08:36:15+00:00">{item["entry-date-updated"]}
                                        </time>
                                    </div>
                                    <div className="entry-summary"><p>{item["desc"]}</p>
                                    </div>

                                </div>
                                </Link>
                            </div>
                        </article>);
                    })}


                </div>

                {/*<nav className="navigation pagination" role="navigation">*/}
                    {/*<h2 className="screen-reader-text">Posts navigation</h2>*/}
                    {/*<div className="nav-links"><span aria-current="page" className="page-numbers current">1</span>*/}
                        {/*<a className="page-numbers" href="http://itsdaum.com/work/page/2/">2</a>*/}
                        {/*<a className="page-numbers" href="http://itsdaum.com/work/page/3/">3</a>*/}
                        {/*<a className="next page-numbers" href="http://itsdaum.com/work/page/2/">*/}
                            {/*<div className="icon-right-open-big"></div>*/}
                        {/*</a></div>*/}
                {/*</nav>*/}


            </div>

        </div>

    );
}

export default WorkThumbDetail;