import gal636352 from "./uploads/workArt/gal636352.png";
import blake21024676 from "./uploads/workArt/blake21024676.png";
import ClubNight1024738 from "./uploads/workArt/ClubNight1024738.png";
import EmilyRudd1024751 from "./uploads/workArt/EmilyRudd1024751.png";
import Kyla27491024 from "./uploads/workArt/Kyla27491024.png";
import sketch7151024566 from "./uploads/workArt/sketch7151024566.png";
import SkullGirl1024881 from "./uploads/workArt/SkullGirl1024881.png";

const workData = [
    {"page-name":"life-portraits",
        "main-img":"gal636352",
        "src-set1":"irori.png 800w",
        "src-set2":"irori-300x113.png 300w",
        "src-set3":"irori-768x288.png 768w",
        "src-set4":"irori-400x150.png 400w",
        "h-5":"Portraits",
        "entry-date-posted-on":"April 2, 2016",
        "entry-date-updated":"January 24, 2020",
        "desc":"Portrait drawings of celebrities and friends.",
        "page-item-data":{"sm-imgs":[
                "blake21024676",
                "ClubNight1024738",
                "EmilyRudd1024751",
                "Kyla27491024",
                "sketch7151024566",
                "SkullGirl1024881"
            ],
            "desc":"<p>Portrait drawings of celebrities and friends.</p><p><strong>Tools Used</strong>: Photoshop, Procreate (Ipad)</p>",
            "credit":"",
            "tags":"<div id=\"tags\"><span class=\"icon-tags\"></span> <p><a href=\"http://itsdaum.com/tag/life/\" rel=\"tag\">life</a>, <a href=\"http://itsdaum.com/tag/live-portrait/\" rel=\"tag\">live portrait</a>, <a href=\"http://itsdaum.com/tag/photorealistic/\" rel=\"tag\">photorealistic</a>, <a href=\"http://itsdaum.com/tag/photoshop/\" rel=\"tag\">Photoshop</a></p></div>"
        }
    },
    {"page-name":"emotion",
        "main-img":"DDbanners2",
        "src-set2":"DDbanners2-300x113.png 300w",
        "src-set3":"DDbanners2-768x288.png 768w",
        "src-set4":"DDbanners2-400x150.png 400w",
        "h-5":"Emotion",
        "entry-date-posted-on":"March 26, 2019",
        "entry-date-updated":"November 27, 2019",
        "desc":"An illustration exploring doubt.",
        "page-item-data":{"sm-imgs":[
                
            ],
            "desc":"<p>I wanted to focus on conveying the emotion of doubt.</p>",
            "credit":"",
            "tags":""
        }
    }

  ];

export default workData;