import React from 'react';
import workData from '../../data/workArtData';
import workImgs from '../../data/uploads/workArt';
import {Link, useParams} from 'react-router-dom';
import daum_profile_r from '../../data/uploads/about/daum_profile_r.jpeg';

function ArtItem() {

    const tgtItem = useParams().item;
    const tgtItemDataArr= workData.filter(item => item["main-img"] == tgtItem);
    const tgtItemData = tgtItemDataArr[0];
    const tgtItemImgs = workImgs[tgtItem+'-imgs'];

    //console.log(tgtItem+'-imgs');
    return(

        <div id="container" className="row" style={{"textAlign":"left"}}>
            <div id="primary" className="large-7 medium-8 small-11 small-centered columns">
                <article
                    className="articlebox post-949 post type-post status-publish format-standard has-post-thumbnail hentry category-games category-work">

                    <header className="entry-header entry-header-single">
                        <h1 className="entry-title">
                            {tgtItemData["h-5"]}</h1>
                        <div className="entry-meta">Daum Park · <span className="screen-reader-text">Posted on</span>
                            <time className="entry-date published" dateTime="2019-10-04T07:48:01+00:00">{tgtItemData["entry-date-posted-on"]}
                            </time>
                            <time className="updated" dateTime="2019-11-26T08:36:15+00:00">{tgtItemData["entry-date-updated"]}</time>
                        </div>
                    </header>
                    <div className="entry-content">

                        <ul className="wp-block-gallery columns-1 is-cropped">
                            <li className="blocks-gallery-item">
                                <figure><img src={tgtItemImgs[tgtItemData["main-img"]]} alt="" data-id="954"
                                             className="wp-image-954"
                                             sizes="(max-width: 800px) 100vw, 800px"/></figure>
                            </li>
                        </ul>

                        <div dangerouslySetInnerHTML={{__html: tgtItemData["page-item-data"]["desc"]}}   ></div>

                        <ul className="wp-block-gallery aligncenter columns-2 is-cropped alignwide" >
                            {
                                tgtItemData["page-item-data"]["sm-imgs"].map( smImg =>
                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <a href={"../download/image/"+smImg}>
                                                <img src={tgtItemImgs[smImg]}
                                                     alt=""
                                                     data-id="950"
                                                     data-link={"../download/image/"+smImg+".png"}
                                                     className="wp-image-950"
                                                     sizes="(max-width:800px) 100vw, 800px"
                                                />
                                            </a>
                                        </figure>
                                    </li>
                                )
                            }
                        </ul>

                        <div dangerouslySetInnerHTML={{__html: tgtItemData["page-item-data"]["credit"]}}   ></div>

                    </div>

                </article>

                <div className="entry-meta cat-and-tags">
                    <div id="categories"><span className="icon-archive"></span> <p>
                        <Link to="/work-game" rel="category tag">Games</Link>,
                        <Link to="/work-art" rel="category tag">Work</Link>
                    </p></div>
                    <div dangerouslySetInnerHTML={{__html: tgtItemData["page-item-data"]["tags"]}}   ></div>
                </div>



            </div>

            <div className="row">
                <div className="large-7 medium-8 small-11 small-centered columns">
                    <div id="author-info" className="columns row callout">
                        <div className="small-2 columns">
                            <img alt="" src={daum_profile_r}
                                 className="avatar avatar-120 photo" height="120" width="120"/></div>
                        <div className="small-10 columns">
                            <h5 className="author-title">Daum Park</h5>
                            Crafting meaningful games that celebrate human imagination.
                        </div>
                    </div>


                </div>
            </div>


        </div>);
}

export default ArtItem;