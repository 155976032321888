import React from 'react';
import WebsiteBanner from '../../data/uploads/home/WebsiteBanner.png';

function Home() {
    
    return (
            <div id="container" className="row">
                <div id="primary" className="large-7 medium-9 small-11 small-centered columns">
                    <article className="articlebox post-75 page type-page status-publish has-post-thumbnail hentry">
                        <header className="entry-header entry-header-single">
                            <h1 className="entry-title">
                                Capturing &amp; Celebrating Human Imagination </h1>
                        </header>
                        <div className="entry-content">

                            <div className="wp-block-image">
                                <figure className="aligncenter">
                                    <img src={WebsiteBanner} alt=""                                                               className="wp-image-829"

                                                                     sizes="(max-width: 1400px) 100vw, 1400px"/></figure>
                            </div>


                            <p>Hi there! I’m Daum, pronounced Dah Uhm, a game designer, programmer, based in Sydney.
                                Working towards <strong>Capturing</strong> &amp; <strong>Celebrating</strong> <strong>Human
                                    Imagination</strong> with <strong>Games</strong>.</p>


                            <p style={{'textAlign':'center'}} className="has-background has-white-background-color"><a
                                rel="noreferrer noopener" href="https://itsnemo.itch.io/" target="_blank"><strong>Play
                                Playful
                                Interactives</strong></a> <br/><a href="https://itsdaum.com/now"><strong>What I’m Doing
                                Now</strong></a><br/>
                                <strong><a href="https://itsdaum.com/about">Me in 60 seconds</a></strong>
                                <br/>My<strong> <a
                                    href="https://itsdaum.com/work-game/">Games </a></strong>and <strong><a
                                    href="https://itsdaum.com/work-art">Art</a></strong><br/><strong><a
                                    href="https://twitter.com/itsDaumPark">Follow my journey</a></strong><br/><strong><a
                                    href="https://ko-fi.com/thinkertoys">Shout me a coffee</a></strong><br/></p>
                        </div>
                        {/*<!-- .entry-content -->*/}
                    </article>
                </div>
               

            </div>


);
}

export default Home;



