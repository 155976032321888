import React from 'react';
import logo from './logo.svg';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './routes/home/Home';
import Now from './routes/now/Now';
import About from './routes/about/About';
import Game from './routes/work/Game';
import Art from './routes/work/Art';
import GameItem from './routes/work/GameItem';
import ArtItem from './routes/work/ArtItem';
import Contacts from './routes/contacts/Contacts';
//------apply react-router-dom------------
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';


//------------------


import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }


// class App extends React.Component{
//
//   // constructor() {
//   //   super();
//   // }
//   //React.Component에 있는 render method overriding
//   render(){
//     return (
//         <div className="App">
//             <Header/>
//          {/*<header className="App-header">*/}
//             {/*<img src={logo} className="App-logo" alt="logo" />*/}
//             {/*<p>*/}
//                 {/*Edit <code>src/App.js</code> and save to reload*/}
//
//             {/*</p>*/}
//             {/*<a className="App-link"*/}
//                 {/*href="https://reactjs.org"*/}
//                {/*target="_blank"*/}
//                {/*rel="noopener noreferrer"*/}
//             {/*>*/}
//               {/*Learn React*/}
//
//
//             {/*</a>*/}
//           {/*</header>*/}
//           <Home/>
//           <Footer/>
//         </div>
//     );
//   }
//
// }

class App extends React.Component{

    // constructor() {
    //   super();
    // }
    //React.Component에 있는 render method overriding
    render(){
        return (

            <Router>
                <div className="App">
                    <Header/>
                <Switch>

                    <Route path="/now">
                        <Now/>
                    </Route>
                    <Route path="/about">
                        <About/>
                    </Route>
                    <Route path="/work-game/:item">
                        <GameItem/>
                    </Route>
                    <Route path="/work-art/:item">
                        <ArtItem/>
                    </Route>

                    <Route path="/work-game">
                        <Game/>
                    </Route>
                    <Route path="/work-art">
                        <Art/>
                    </Route>
                    <Route path="/contacts">
                        <Contacts/>
                    </Route>
                    <Route path="/" exact>
                        <Home/>
                    </Route>
                </Switch>
                    <Footer/>
                </div>

            </Router>

        );
    }

}



export default App;

/*

https://reacttraining.com/react-router/web/example/url-params

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

// Params are placeholders in the URL that begin
// with a colon, like the `:id` param defined in
// the route in this example. A similar convention
// is used for matching dynamic segments in other
// popular web frameworks like Rails and Express.

export default function ParamsExample() {
  return (
    <Router>
      <div>
        <h2>Accounts</h2>

        <ul>
          <li>
            <Link to="/netflix">Netflix</Link>
          </li>
          <li>
            <Link to="/zillow-group">Zillow Group</Link>
          </li>
          <li>
            <Link to="/yahoo">Yahoo</Link>
          </li>
          <li>
            <Link to="/modus-create">Modus Create</Link>
          </li>
        </ul>

        <Switch>
          <Route path="/:id" children={<Child />} />
        </Switch>
      </div>
    </Router>
  );
}

function Child() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { id } = useParams();

  return (
    <div>
      <h3>ID: {id}</h3>
    </div>
  );
}

 */
