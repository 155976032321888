import React from 'react';


function Now() {

    return (

        <div id="container" className="row">
            <div id="primary" className="large-7 medium-9 small-11 small-centered columns">
                <article className="articlebox post-1080 page type-page status-publish hentry">
                    <header className="entry-header entry-header-single">
                        <h1 className="entry-title">
                            What I’m Doing Now </h1>
                    </header>
                    <div className="entry-content">

                        <ul style={{"textAlign": "left"}}>
                            <li>Last spotted in Australia, Sydney, Chatswood.</li>
                            <li>Re-designed my Online Portfolio. It’s now more minimal.</li>
                            <li>I started a <strong>work </strong>journal to
                                capture <strong>ideas</strong>, <strong>inspiration</strong>,
                                and <strong>knowledge</strong>.
                            </li>
                            <li>Pushing my climbing limits at <a href="https://9degreeslanecove.com.au/">9 Degrees Lane
                                Cove.</a> Currently climbing V3-V6.
                            </li>
                        </ul>


                        <h4>What I’m Creating</h4>


                        <ul style={{"textAlign": "left"}}>
                            <li>Crafting <a href="https://itsnemo.itch.io/">Playful Interactives</a> focused
                                on <strong>Creativity</strong>, <strong>Minimalism, </strong>and <strong>AR</strong>.
                            </li>
                            <li>The next project is in its Ideation phase.</li>
                            <li>Started a DIY low budget planted aquarium inside a <a
                                href="https://www.ballmason.com.au/image/cache/catalog/Stock/bormioli/Fido/1-x-3-litre-fido-swing-top-preserving-bottle-jar-bormioli-rocco-550x550.jpg">Fido
                                Jar.</a> So far it’s doing well, plants are growing and looking healthy.
                            </li>
                            <li>Released “<a href="https://itsnemo.itch.io/">The Fly</a>” – a game about swatting flies
                                on your desktop. Exploring the definition of playing games on Windows OS.
                            </li>
                            <li>Released an alpha version of “<a href="https://pomepomelo.itch.io/irori">irori</a>” – a
                                cosy game about cooking warm food for weary travelers.
                            </li>
                        </ul>


                        <h4>What I’m Learning</h4>


                        <ul style={{"textAlign": "left"}}>
                            <li>AR kit, AR Core and Vuforia, to be able to make more AR-related projects.</li>
                            <li>I started solving algorithms daily on HackerRank.</li>
                            <li>Practicing <a href="http://www.wellnesstips.ca/one%20breath%20meditation.htm">One Breath
                                Meditation</a> to improve mental focus.
                            </li>
                        </ul>


                        <h4>What I’m Reading</h4>


                        <ul style={{"textAlign": "left"}}>
                            <li><a
                                href="https://www.goodreads.com/book/show/31823677-tools-of-titans?ac=1&amp;from_search=true&amp;qid=SpAX3XDnMw&amp;rank=1">Tools
                                of Titans by Tim Ferris.</a> A collection of tools and lessons from his Podcast with
                                High-performing individuals.
                            </li>
                            <li><a href="https://www.goodreads.com/en/book/show/40591677-keep-going">Keep Going by
                                Austin Kleon</a>. A wonderful book containing strategies to keep you creative.
                            </li>
                        </ul>


                        <h4>What I’m Listening to</h4>


                        <ul style={{"textAlign": "left"}}>
                            <li><a href="https://gimletmedia.com/shows/reply-all">Reply All Podcast by Gimlet
                                Media.</a> Listening to Alex and PJ always puts a smile on my face. They uncover
                                fascinating stories that occur around us involving technology.
                            </li>
                            <li><a href="https://gimletmedia.com/shows/science-vs">Science Vs. by Gimlet
                                Media.</a> There are podcasts and then there’s Science.
                            </li>
                            <li><a href="https://tim.blog/podcast/">The Tim Ferris Show by Tim Ferris.</a> Interviews
                                with high-performing individuals. A plethora of knowledge to optimise life.
                            </li>
                            <li><a href="https://99percentinvisible.org/">99% invisible by Roman Mars.</a> His voice is
                                a delight.
                            </li>
                        </ul>


                        <p style={{"textAlign": "center"}}><strong>All paths lead to living an essential and fulfilling
                            life. </strong><br/></p>


                        <p><em>Updated 27th November 2019</em> <a href="https://twitter.com/itsDaumPark">Live Updates
                            Here.</a><br/><em>This page was inspired by </em><a href="https://nownownow.com/"><em>Derek
                            Sivers’ nownownow.com</em></a></p>
                    </div>

                </article>
            </div>


        </div>
    );

}

export default Now;