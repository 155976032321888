import React from 'react';


function Contacts() {
    return (

        <div id="container" className="row">

            <div id="primary" className="large-7 medium-9 small-11 small-centered columns">
                <article className="articlebox post-594 page type-page status-publish hentry">
                    <header className="entry-header entry-header-single">
                        <h1 className="entry-title">
                            Contacts </h1>
                    </header>
                    <div className="entry-content">

                        <p style={{"textAlign":"left"}}><strong>Email</strong>: claumpark (at) gmail.com</p>


                        <p style={{"textAlign":"left"}}><strong>Linkedin:</strong> <a
                            href="http://www.linkedin.com/in/daumpark">Daum
                            Park </a></p>


                        <p style={{"textAlign":"left"}}><strong>Twitter: </strong><a
                            href="https://twitter.com/itsDaumPark">@itsDaumPark</a></p>


                        <div className="wp-block-file">
                            <a href="/download/DaumPark_Resume.pdf">DaumPark_Resume</a>
                            <a href="/download/DaumPark_Resume.pdf" className="wp-block-file__button" download="">Download</a>
                        </div>


                        <p></p>
                    </div>

                </article>
            </div>


        </div>
    );
}

export default Contacts;