import beamBanner from './beamBanner.png';
import cloudedbanners from './cloudedbanners.png';
import DDbanners2 from './DDbanners2.png';
import irori from './irori.png';
import magpieBanner from './magpieBanner.png';
import paleBanner from './paleBanner.png';
import secretBanner from './secretBanner.png';
import soundBanner from './soundBanner.png';
import stumpedbanner from './stumpedbanner.png';
import ZGO__i3 from './ZGO__i3.png';


import alicebanner from './alicebanner.png';
import bombbanner from './bombbanner.png';
import brbbanners from './brbbanners.png';
import cosmosbanner from './cosmosbanner.png';
import crybanners from './crybanners.png';
import gal636352 from './gal636352.png';
import gsNl3i from './gsNl3i.png';
import jackbanner from './jackbanner.png';
import linkbanner from './linkbanner.png';
import lupabanner from './lupabanner.png';
import machobanners from './machobanners.png';
import magicbanner from './magicbanner.png';
import OverallGuideline3 from './OverallGuideline3.png';
import ritualbanners from './ritualbanners.png';

import RWFKO1024465_sm1 from './RWFKO1024465_sm1.png';
import RWFKO1024465_sm2 from './RWFKO1024465_sm2.png';
import RWFKO1024465_sm3 from './RWFKO1024465_sm3.png';

import sqsSa_sm1 from './sqsSa_sm1.png';
import sqsSa_sm2 from './sqsSa_sm2.png';
import sqsSa_sm3 from './sqsSa_sm3.jpeg';
import sqsSa_sm4 from './sqsSa_sm4.jpeg';
import sqsSa_sm5 from './sqsSa_sm5.jpeg';
import sqsSa_sm6 from './sqsSa_sm6.png';
import sqsSa_sm7 from './sqsSa_sm7.png';

import alicebanner_sm1 from './alicebanner_sm1.png';
import alicebanner_sm2 from './alicebanner_sm2.png';
import alicebanner_sm3 from './alicebanner_sm3.png';

import bombbanner_sm1 from './bombbanner_sm1.png';
import bombbanner_sm2 from './bombbanner_sm2.png';
import bombbanner_sm3 from './bombbanner_sm3.png';
import bombbanner_sm4 from './bombbanner_sm4.png';

import BRB_sm1 from './BRB_sm1.png';
import BRB_sm2 from './BRB_sm2.png';

import cloudedbanners_sm1 from './cloudedbanners_sm1.png';
import cloudedbanners_sm2 from './cloudedbanners_sm2.png';
import cloudedbanners_sm3 from './cloudedbanners_sm3.png';

import cosmosbanner_sm1 from './cosmosbanner_sm1.png';
import cosmosbanner_sm2 from './cosmosbanner_sm2.png';
import cosmosbanner_sm3 from './cosmosbanner_sm3.png';

import crybanners_sm1 from './crybanners_sm1.jpg';
import crybanners_sm2 from './crybanners_sm2.jpg';
import crybanners_sm3 from './crybanners_sm3.jpg';

import gsNl3i_sm1 from './gsNl3i_sm1.png';
import gsNl3i_sm2 from './gsNl3i_sm2.png';
import gsNl3i_sm3 from './gsNl3i_sm3.png';

import irori_sm1 from './irori_sm1.gif';
import irori_sm2 from './irori_sm2.gif';
import irori_sm3 from './irori_sm3.gif';
import irori_sm4 from './irori_sm4.gif';

import jackbanner_sm1 from './jackbanner_sm1.png';
import jackbanner_sm2 from './jackbanner_sm2.png';
import jackbanner_sm3 from './jackbanner_sm3.png';

import linkbanner_sm1 from './linkbanner_sm1.png';
import linkbanner_sm2 from './linkbanner_sm2.png';
import linkbanner_sm3 from './linkbanner_sm3.png';


import lupabanner_sm1 from './lupabanner_sm1.png';
import lupabanner_sm2 from './lupabanner_sm2.png';
import lupabanner_sm3 from './lupabanner_sm3.png';

import machobanners_sm1 from './machobanners_sm1.png';
import machobanners_sm2 from './machobanners_sm2.png';
import machobanners_sm3 from './machobanners_sm3.png';
import machobanners_sm4 from './machobanners_sm4.png';
import machobanners_sm5 from './machobanners_sm5.png';

import magicbanner_sm1 from './magicbanner_sm1.jpg';
import magicbanner_sm2 from './magicbanner_sm2.jpg';

import magpieBanner_sm1 from './magpieBanner_sm1.png';
import magpieBanner_sm2 from './magpieBanner_sm2.png';
import magpieBanner_sm3 from './magpieBanner_sm3.png';

import OgKvT1024267_sm1 from './OgKvT1024267_sm1.png';
import OgKvT1024267_sm2 from './OgKvT1024267_sm2.png';
import OgKvT1024267_sm3 from './OgKvT1024267_sm3.png';


import OverallGuideline3_sm1 from './OverallGuideline3_sm1.jpg';
import OverallGuideline3_sm2 from './OverallGuideline3_sm2.png';

import paleBanner_sm1 from './paleBanner_sm1.png';
import paleBanner_sm2 from './paleBanner_sm2.png';
import paleBanner_sm3 from './paleBanner_sm3.png';

import ritualbanners_sm1 from './ritualbanners_sm1.png';
import ritualbanners_sm2 from './ritualbanners_sm2.png';
import ritualbanners_sm3 from './ritualbanners_sm3.png';

import secretBanner_sm1 from './secretBanner_sm1.png';
import secretBanner_sm2 from './secretBanner_sm2.png';
import secretBanner_sm3 from './secretBanner_sm3.png';

import ZGO__i3_sm1 from './ZGO__i3_sm1.png';
import ZGO__i3_sm2 from './ZGO__i3_sm2.png';
import ZGO__i3_sm3 from './ZGO__i3_sm3.png';


const workGameImgs = {

    'beamBanner-imgs' : {
        beamBanner,
        sqsSa_sm1,
        sqsSa_sm2,
        sqsSa_sm3,
        sqsSa_sm4,
        sqsSa_sm5,
        sqsSa_sm6,
        sqsSa_sm7,
    },
    'cloudedbanners-imgs' : {
        cloudedbanners,
        cloudedbanners_sm1,
        cloudedbanners_sm2,
        cloudedbanners_sm3,

    },
    'DDbanners2-imgs' : {
        DDbanners2,

    },
    'irori-imgs' : {
        irori,
        irori_sm1,
        irori_sm2,
        irori_sm3,
        irori_sm4,
    },
    'magpieBanner-imgs' : {
        magpieBanner,
        magpieBanner_sm1,
        magpieBanner_sm2,
        magpieBanner_sm3,
    },
    'paleBanner-imgs' : {
        paleBanner,
        paleBanner_sm1,
        paleBanner_sm2,
        paleBanner_sm3,
    },
    'secretBanner-imgs' : {
        secretBanner,
        secretBanner_sm1,
        secretBanner_sm2,
        secretBanner_sm3,
    },
    'soundBanner-imgs' : {
        soundBanner,
        OgKvT1024267_sm1,
        OgKvT1024267_sm2,
        OgKvT1024267_sm3,
    },
    'stumpedbanner-imgs' : {
        stumpedbanner,
        RWFKO1024465_sm1,
        RWFKO1024465_sm2,
        RWFKO1024465_sm3,
    },
    'ZGO__i3-imgs' : {
        ZGO__i3,
        ZGO__i3_sm1,
        ZGO__i3_sm2,
        ZGO__i3_sm3,
    },

    'alicebanner-imgs' : {
        alicebanner,
        alicebanner_sm1,
        alicebanner_sm2,
        alicebanner_sm3,

    },
    'bombbanner-imgs' : {
        bombbanner,
        bombbanner_sm1,
        bombbanner_sm2,
        bombbanner_sm3,
        bombbanner_sm4,
    },
    'brbbanners-imgs' : {
        brbbanners,
        BRB_sm1,
        BRB_sm2,
    },
    'cosmosbanner-imgs' : {
        cosmosbanner,
        cosmosbanner_sm1,
        cosmosbanner_sm2,
        cosmosbanner_sm3,
    },
    'crybanners-imgs' : {
        crybanners,
        crybanners_sm1,
        crybanners_sm2,
        crybanners_sm3,

    },
    'gal636352-imgs' : {
        gal636352,

    },
    'gsNl3i-imgs' : {
        gsNl3i,
        gsNl3i_sm1,
        gsNl3i_sm2,
        gsNl3i_sm3,
    },
    'jackbanner-imgs' : {
        jackbanner,
        jackbanner_sm1,
        jackbanner_sm2,
        jackbanner_sm3,
    },
    'linkbanner-imgs' : {
        linkbanner,
        linkbanner_sm1,
        linkbanner_sm2,
        linkbanner_sm3,
    },
    'lupabanner-imgs' : {
        lupabanner,
        lupabanner_sm1,
        lupabanner_sm2,
        lupabanner_sm3,
    },
    'machobanners-imgs' : {
        machobanners,
        machobanners_sm1,
        machobanners_sm2,
        machobanners_sm3,
        machobanners_sm4,
        machobanners_sm5,
    },
    'magicbanner-imgs' : {
        magicbanner,
        magicbanner_sm1,
        magicbanner_sm2,
    },
    'OverallGuideline3-imgs' : {
        OverallGuideline3,
        OverallGuideline3_sm1,
        OverallGuideline3_sm2,
    },
    'ritualbanners-imgs' : {
        ritualbanners,
        ritualbanners_sm1,
        ritualbanners_sm2,
        ritualbanners_sm3,
    },





};


export default workGameImgs;